<template>
  <div class="pa-10">
    <div v-if="this.privacyPolicy" class="d-flex justify-center">
      <vue-markdown>
        {{this.privacyPolicy}}
      </vue-markdown>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueMarkdown from "vue-markdown";

export default {
  name: "base-privacy-policy-component",
  props: {},
  model: {
    event: 'updateModel'
  },
  components: {VueMarkdown},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('siteContentsStore', {
      privacyPolicy: 'getPrivacyPolicy'
    })
  },
  methods: {},
  watch: {},
  mounted() {
    console.log('debugger')
  }
}
</script>

<style scoped>

</style>